/*
* Font Face
*/
@font-face {
  font-family: 'simple-line-icons';
  src: url(/static/media/Simple-Line-Icons.f33df365.eot);
  src: url(/static/media/Simple-Line-Icons.f33df365.eot#iefix) format("embedded-opentype"), url(/static/media/Simple-Line-Icons.0cb0b9c5.woff2) format("woff2"), url(/static/media/Simple-Line-Icons.d2285965.ttf) format("truetype"), url(/static/media/Simple-Line-Icons.78f07e2c.woff) format("woff"), url(/static/media/Simple-Line-Icons.2a103029.svg#simple-line-icons) format("svg");
  font-weight: normal;
  font-style: normal; }

/*
 Use the following code if you want to have a class per icon.
 Instead of a list of all class selectors, you can use the generic [class*="icon-"] selector,
 but it's slower.
*/
.icon-user,
.icon-people,
.icon-user-female,
.icon-user-follow,
.icon-user-following,
.icon-user-unfollow,
.icon-login,
.icon-logout,
.icon-emotsmile,
.icon-phone,
.icon-call-end,
.icon-call-in,
.icon-call-out,
.icon-map,
.icon-location-pin,
.icon-direction,
.icon-directions,
.icon-compass,
.icon-layers,
.icon-menu,
.icon-list,
.icon-options-vertical,
.icon-options,
.icon-arrow-down,
.icon-arrow-left,
.icon-arrow-right,
.icon-arrow-up,
.icon-arrow-up-circle,
.icon-arrow-left-circle,
.icon-arrow-right-circle,
.icon-arrow-down-circle,
.icon-check,
.icon-clock,
.icon-plus,
.icon-minus,
.icon-close,
.icon-event,
.icon-exclamation,
.icon-organization,
.icon-trophy,
.icon-screen-smartphone,
.icon-screen-desktop,
.icon-plane,
.icon-notebook,
.icon-mustache,
.icon-mouse,
.icon-magnet,
.icon-energy,
.icon-disc,
.icon-cursor,
.icon-cursor-move,
.icon-crop,
.icon-chemistry,
.icon-speedometer,
.icon-shield,
.icon-screen-tablet,
.icon-magic-wand,
.icon-hourglass,
.icon-graduation,
.icon-ghost,
.icon-game-controller,
.icon-fire,
.icon-eyeglass,
.icon-envelope-open,
.icon-envelope-letter,
.icon-bell,
.icon-badge,
.icon-anchor,
.icon-wallet,
.icon-vector,
.icon-speech,
.icon-puzzle,
.icon-printer,
.icon-present,
.icon-playlist,
.icon-pin,
.icon-picture,
.icon-handbag,
.icon-globe-alt,
.icon-globe,
.icon-folder-alt,
.icon-folder,
.icon-film,
.icon-feed,
.icon-drop,
.icon-drawer,
.icon-docs,
.icon-doc,
.icon-diamond,
.icon-cup,
.icon-calculator,
.icon-bubbles,
.icon-briefcase,
.icon-book-open,
.icon-basket-loaded,
.icon-basket,
.icon-bag,
.icon-action-undo,
.icon-action-redo,
.icon-wrench,
.icon-umbrella,
.icon-trash,
.icon-tag,
.icon-support,
.icon-frame,
.icon-size-fullscreen,
.icon-size-actual,
.icon-shuffle,
.icon-share-alt,
.icon-share,
.icon-rocket,
.icon-question,
.icon-pie-chart,
.icon-pencil,
.icon-note,
.icon-loop,
.icon-home,
.icon-grid,
.icon-graph,
.icon-microphone,
.icon-music-tone-alt,
.icon-music-tone,
.icon-earphones-alt,
.icon-earphones,
.icon-equalizer,
.icon-like,
.icon-dislike,
.icon-control-start,
.icon-control-rewind,
.icon-control-play,
.icon-control-pause,
.icon-control-forward,
.icon-control-end,
.icon-volume-1,
.icon-volume-2,
.icon-volume-off,
.icon-calendar,
.icon-bulb,
.icon-chart,
.icon-ban,
.icon-bubble,
.icon-camrecorder,
.icon-camera,
.icon-cloud-download,
.icon-cloud-upload,
.icon-envelope,
.icon-eye,
.icon-flag,
.icon-heart,
.icon-info,
.icon-key,
.icon-link,
.icon-lock,
.icon-lock-open,
.icon-magnifier,
.icon-magnifier-add,
.icon-magnifier-remove,
.icon-paper-clip,
.icon-paper-plane,
.icon-power,
.icon-refresh,
.icon-reload,
.icon-settings,
.icon-star,
.icon-symbol-female,
.icon-symbol-male,
.icon-target,
.icon-credit-card,
.icon-paypal,
.icon-social-tumblr,
.icon-social-twitter,
.icon-social-facebook,
.icon-social-instagram,
.icon-social-linkedin,
.icon-social-pinterest,
.icon-social-github,
.icon-social-google,
.icon-social-reddit,
.icon-social-skype,
.icon-social-dribbble,
.icon-social-behance,
.icon-social-foursqare,
.icon-social-soundcloud,
.icon-social-spotify,
.icon-social-stumbleupon,
.icon-social-youtube,
.icon-social-dropbox,
.icon-social-vkontakte,
.icon-social-steam {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-user:before {
  content: "\E005"; }

.icon-people:before {
  content: "\E001"; }

.icon-user-female:before {
  content: "\E000"; }

.icon-user-follow:before {
  content: "\E002"; }

.icon-user-following:before {
  content: "\E003"; }

.icon-user-unfollow:before {
  content: "\E004"; }

.icon-login:before {
  content: "\E066"; }

.icon-logout:before {
  content: "\E065"; }

.icon-emotsmile:before {
  content: "\E021"; }

.icon-phone:before {
  content: "\E600"; }

.icon-call-end:before {
  content: "\E048"; }

.icon-call-in:before {
  content: "\E047"; }

.icon-call-out:before {
  content: "\E046"; }

.icon-map:before {
  content: "\E033"; }

.icon-location-pin:before {
  content: "\E096"; }

.icon-direction:before {
  content: "\E042"; }

.icon-directions:before {
  content: "\E041"; }

.icon-compass:before {
  content: "\E045"; }

.icon-layers:before {
  content: "\E034"; }

.icon-menu:before {
  content: "\E601"; }

.icon-list:before {
  content: "\E067"; }

.icon-options-vertical:before {
  content: "\E602"; }

.icon-options:before {
  content: "\E603"; }

.icon-arrow-down:before {
  content: "\E604"; }

.icon-arrow-left:before {
  content: "\E605"; }

.icon-arrow-right:before {
  content: "\E606"; }

.icon-arrow-up:before {
  content: "\E607"; }

.icon-arrow-up-circle:before {
  content: "\E078"; }

.icon-arrow-left-circle:before {
  content: "\E07A"; }

.icon-arrow-right-circle:before {
  content: "\E079"; }

.icon-arrow-down-circle:before {
  content: "\E07B"; }

.icon-check:before {
  content: "\E080"; }

.icon-clock:before {
  content: "\E081"; }

.icon-plus:before {
  content: "\E095"; }

.icon-minus:before {
  content: "\E615"; }

.icon-close:before {
  content: "\E082"; }

.icon-event:before {
  content: "\E619"; }

.icon-exclamation:before {
  content: "\E617"; }

.icon-organization:before {
  content: "\E616"; }

.icon-trophy:before {
  content: "\E006"; }

.icon-screen-smartphone:before {
  content: "\E010"; }

.icon-screen-desktop:before {
  content: "\E011"; }

.icon-plane:before {
  content: "\E012"; }

.icon-notebook:before {
  content: "\E013"; }

.icon-mustache:before {
  content: "\E014"; }

.icon-mouse:before {
  content: "\E015"; }

.icon-magnet:before {
  content: "\E016"; }

.icon-energy:before {
  content: "\E020"; }

.icon-disc:before {
  content: "\E022"; }

.icon-cursor:before {
  content: "\E06E"; }

.icon-cursor-move:before {
  content: "\E023"; }

.icon-crop:before {
  content: "\E024"; }

.icon-chemistry:before {
  content: "\E026"; }

.icon-speedometer:before {
  content: "\E007"; }

.icon-shield:before {
  content: "\E00E"; }

.icon-screen-tablet:before {
  content: "\E00F"; }

.icon-magic-wand:before {
  content: "\E017"; }

.icon-hourglass:before {
  content: "\E018"; }

.icon-graduation:before {
  content: "\E019"; }

.icon-ghost:before {
  content: "\E01A"; }

.icon-game-controller:before {
  content: "\E01B"; }

.icon-fire:before {
  content: "\E01C"; }

.icon-eyeglass:before {
  content: "\E01D"; }

.icon-envelope-open:before {
  content: "\E01E"; }

.icon-envelope-letter:before {
  content: "\E01F"; }

.icon-bell:before {
  content: "\E027"; }

.icon-badge:before {
  content: "\E028"; }

.icon-anchor:before {
  content: "\E029"; }

.icon-wallet:before {
  content: "\E02A"; }

.icon-vector:before {
  content: "\E02B"; }

.icon-speech:before {
  content: "\E02C"; }

.icon-puzzle:before {
  content: "\E02D"; }

.icon-printer:before {
  content: "\E02E"; }

.icon-present:before {
  content: "\E02F"; }

.icon-playlist:before {
  content: "\E030"; }

.icon-pin:before {
  content: "\E031"; }

.icon-picture:before {
  content: "\E032"; }

.icon-handbag:before {
  content: "\E035"; }

.icon-globe-alt:before {
  content: "\E036"; }

.icon-globe:before {
  content: "\E037"; }

.icon-folder-alt:before {
  content: "\E039"; }

.icon-folder:before {
  content: "\E089"; }

.icon-film:before {
  content: "\E03A"; }

.icon-feed:before {
  content: "\E03B"; }

.icon-drop:before {
  content: "\E03E"; }

.icon-drawer:before {
  content: "\E03F"; }

.icon-docs:before {
  content: "\E040"; }

.icon-doc:before {
  content: "\E085"; }

.icon-diamond:before {
  content: "\E043"; }

.icon-cup:before {
  content: "\E044"; }

.icon-calculator:before {
  content: "\E049"; }

.icon-bubbles:before {
  content: "\E04A"; }

.icon-briefcase:before {
  content: "\E04B"; }

.icon-book-open:before {
  content: "\E04C"; }

.icon-basket-loaded:before {
  content: "\E04D"; }

.icon-basket:before {
  content: "\E04E"; }

.icon-bag:before {
  content: "\E04F"; }

.icon-action-undo:before {
  content: "\E050"; }

.icon-action-redo:before {
  content: "\E051"; }

.icon-wrench:before {
  content: "\E052"; }

.icon-umbrella:before {
  content: "\E053"; }

.icon-trash:before {
  content: "\E054"; }

.icon-tag:before {
  content: "\E055"; }

.icon-support:before {
  content: "\E056"; }

.icon-frame:before {
  content: "\E038"; }

.icon-size-fullscreen:before {
  content: "\E057"; }

.icon-size-actual:before {
  content: "\E058"; }

.icon-shuffle:before {
  content: "\E059"; }

.icon-share-alt:before {
  content: "\E05A"; }

.icon-share:before {
  content: "\E05B"; }

.icon-rocket:before {
  content: "\E05C"; }

.icon-question:before {
  content: "\E05D"; }

.icon-pie-chart:before {
  content: "\E05E"; }

.icon-pencil:before {
  content: "\E05F"; }

.icon-note:before {
  content: "\E060"; }

.icon-loop:before {
  content: "\E064"; }

.icon-home:before {
  content: "\E069"; }

.icon-grid:before {
  content: "\E06A"; }

.icon-graph:before {
  content: "\E06B"; }

.icon-microphone:before {
  content: "\E063"; }

.icon-music-tone-alt:before {
  content: "\E061"; }

.icon-music-tone:before {
  content: "\E062"; }

.icon-earphones-alt:before {
  content: "\E03C"; }

.icon-earphones:before {
  content: "\E03D"; }

.icon-equalizer:before {
  content: "\E06C"; }

.icon-like:before {
  content: "\E068"; }

.icon-dislike:before {
  content: "\E06D"; }

.icon-control-start:before {
  content: "\E06F"; }

.icon-control-rewind:before {
  content: "\E070"; }

.icon-control-play:before {
  content: "\E071"; }

.icon-control-pause:before {
  content: "\E072"; }

.icon-control-forward:before {
  content: "\E073"; }

.icon-control-end:before {
  content: "\E074"; }

.icon-volume-1:before {
  content: "\E09F"; }

.icon-volume-2:before {
  content: "\E0A0"; }

.icon-volume-off:before {
  content: "\E0A1"; }

.icon-calendar:before {
  content: "\E075"; }

.icon-bulb:before {
  content: "\E076"; }

.icon-chart:before {
  content: "\E077"; }

.icon-ban:before {
  content: "\E07C"; }

.icon-bubble:before {
  content: "\E07D"; }

.icon-camrecorder:before {
  content: "\E07E"; }

.icon-camera:before {
  content: "\E07F"; }

.icon-cloud-download:before {
  content: "\E083"; }

.icon-cloud-upload:before {
  content: "\E084"; }

.icon-envelope:before {
  content: "\E086"; }

.icon-eye:before {
  content: "\E087"; }

.icon-flag:before {
  content: "\E088"; }

.icon-heart:before {
  content: "\E08A"; }

.icon-info:before {
  content: "\E08B"; }

.icon-key:before {
  content: "\E08C"; }

.icon-link:before {
  content: "\E08D"; }

.icon-lock:before {
  content: "\E08E"; }

.icon-lock-open:before {
  content: "\E08F"; }

.icon-magnifier:before {
  content: "\E090"; }

.icon-magnifier-add:before {
  content: "\E091"; }

.icon-magnifier-remove:before {
  content: "\E092"; }

.icon-paper-clip:before {
  content: "\E093"; }

.icon-paper-plane:before {
  content: "\E094"; }

.icon-power:before {
  content: "\E097"; }

.icon-refresh:before {
  content: "\E098"; }

.icon-reload:before {
  content: "\E099"; }

.icon-settings:before {
  content: "\E09A"; }

.icon-star:before {
  content: "\E09B"; }

.icon-symbol-female:before {
  content: "\E09C"; }

.icon-symbol-male:before {
  content: "\E09D"; }

.icon-target:before {
  content: "\E09E"; }

.icon-credit-card:before {
  content: "\E025"; }

.icon-paypal:before {
  content: "\E608"; }

.icon-social-tumblr:before {
  content: "\E00A"; }

.icon-social-twitter:before {
  content: "\E009"; }

.icon-social-facebook:before {
  content: "\E00B"; }

.icon-social-instagram:before {
  content: "\E609"; }

.icon-social-linkedin:before {
  content: "\E60A"; }

.icon-social-pinterest:before {
  content: "\E60B"; }

.icon-social-github:before {
  content: "\E60C"; }

.icon-social-google:before {
  content: "\E60D"; }

.icon-social-reddit:before {
  content: "\E60E"; }

.icon-social-skype:before {
  content: "\E60F"; }

.icon-social-dribbble:before {
  content: "\E00D"; }

.icon-social-behance:before {
  content: "\E610"; }

.icon-social-foursqare:before {
  content: "\E611"; }

.icon-social-soundcloud:before {
  content: "\E612"; }

.icon-social-spotify:before {
  content: "\E613"; }

.icon-social-stumbleupon:before {
  content: "\E614"; }

.icon-social-youtube:before {
  content: "\E008"; }

.icon-social-dropbox:before {
  content: "\E00C"; }

.icon-social-vkontakte:before {
  content: "\E618"; }

.icon-social-steam:before {
  content: "\E620"; }

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.scrollbar-container {
  position: relative;
  height: 100%; }

/*# sourceMappingURL=styles.css.map*/
/* rtl fix */
.ps__rtl .ps__rail-y {
    left: 0px !important;
    right: unset;
}
*[dir="rtl"] .ps__rail-y {
    left: 0px !important;
    right: unset;
}

.ps__rtl .ps__thumb-y {
    left: 2px;
    right: unset;
}
*[dir="rtl"] .ps__thumb-y {
  left: 2px;
  right: unset;
}

.rw-btn,
.rw-input-reset,
.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation; }

.rw-btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

select.rw-input, select.rw-dropdown-list-autofill, select.rw-filter-input {
  text-transform: none; }

html input[type="button"].rw-input, html input.rw-dropdown-list-autofill[type="button"], html input.rw-filter-input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer; }

textarea.rw-input, textarea.rw-dropdown-list-autofill, textarea.rw-filter-input {
  overflow: auto;
  resize: vertical; }

button[disabled].rw-input, button.rw-dropdown-list-autofill[disabled], button.rw-filter-input[disabled],
fieldset[disabled] .rw-input,
fieldset[disabled] .rw-dropdown-list-autofill,
fieldset[disabled] .rw-filter-input,
html input[disabled].rw-input,
html input.rw-dropdown-list-autofill[disabled],
html input.rw-filter-input[disabled] {
  cursor: not-allowed; }

button.rw-input::-moz-focus-inner, button.rw-dropdown-list-autofill::-moz-focus-inner, button.rw-filter-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner,
input.rw-dropdown-list-autofill::-moz-focus-inner,
input.rw-filter-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/* -------------- */
@font-face {
  font-family: 'RwWidgets';
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/rw-widgets.bc7c4a59.eot);
  src: url(/static/media/rw-widgets.bc7c4a59.eot?#iefix&v=4.1.0) format("embedded-opentype"), url(/static/media/rw-widgets.12f0820c.woff) format("woff"), url(/static/media/rw-widgets.eceddf47.ttf) format("truetype"), url(/static/media/rw-widgets.792dcd18.svg#fontawesomeregular) format("svg"); }

.rw-i {
  display: inline-block;
  color: inherit;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.rw-i-caret-down:before {
  content: '\E803'; }

.rw-i-caret-up:before {
  content: '\E800'; }

.rw-i-chevron-left:before {
  content: '\F104'; }

.rw-i-chevron-right:before {
  content: '\F105'; }

.rw-i-clock-o:before {
  content: '\E805'; }

.rw-i-calendar:before {
  content: '\E804'; }

.rw-i-search:before {
  content: '\E801'; }

.rw-btn {
  position: relative;
  color: #333333;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none; }
  .rw-state-readonly .rw-btn,
  .rw-state-disabled .rw-btn {
    cursor: not-allowed; }

.rw-btn-select {
  opacity: 0.75;
  transition: opacity 150ms ease-in; }
  .rw-btn-select:hover,
  .rw-state-focus .rw-btn-select,
  :hover > .rw-btn-select {
    opacity: 1; }

.rw-btn-primary {
  width: 100%;
  white-space: normal;
  line-height: 2em; }
  .rw-btn-primary:hover {
    background-color: #e6e6e6; }

.rw-btn-select[disabled],
.rw-btn-primary[disabled],
fieldset[disabled] .rw-btn-select,
fieldset[disabled] .rw-btn-primary {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none; }

/* for debugging */
@-webkit-keyframes react-widgets-autofill-start {
  from {
    /**/ }
  to {
    /**/ } }
@keyframes react-widgets-autofill-start {
  from {
    /**/ }
  to {
    /**/ } }

@-webkit-keyframes react-widgets-autofill-cancel {
  from {
    /**/ }
  to {
    /**/ } }

@keyframes react-widgets-autofill-cancel {
  from {
    /**/ }
  to {
    /**/ } }

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.rw-widget {
  background-clip: border-box;
  border: none;
  color: #333333;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  position: relative; }
  .rw-widget,
  .rw-widget * {
    box-sizing: border-box; }
  .rw-widget:before,
  .rw-widget *:before, .rw-widget:after,
  .rw-widget *:after {
    box-sizing: border-box; }
  .rw-widget > .rw-widget-container {
    width: 100%;
    margin: 0; }

.rw-widget-container {
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 4px; }
  .rw-widget-container.rw-state-focus, .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container,
  .rw-state-focus > .rw-widget-container:hover {
    background-color: #fff;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6); }
  .rw-widget-container.rw-state-readonly,
  .rw-state-readonly > .rw-widget-container {
    cursor: not-allowed; }
  .rw-widget-container.rw-state-disabled, .rw-widget-container.rw-state-disabled:hover, .rw-widget-container.rw-state-disabled:active,
  .rw-state-disabled > .rw-widget-container,
  .rw-state-disabled > .rw-widget-container:hover,
  .rw-state-disabled > .rw-widget-container:active,
  fieldset[disabled] .rw-widget-container,
  fieldset[disabled] .rw-widget-container:hover,
  fieldset[disabled] .rw-widget-container:active {
    box-shadow: none;
    box-shadow: none;
    cursor: not-allowed; }

.rw-widget-picker {
  position: relative;
  overflow: hidden;
  border-collapse: separate;
  display: inline-table;
  height: 2.429em; }
  .rw-widget-picker > * {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    display: table-cell; }
  .rw-widget-picker > .rw-select {
    width: 1%;
    white-space: nowrap; }
  .rw-open > .rw-widget-picker {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .rw-open-up > .rw-widget-picker {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  fieldset[disabled] .rw-widget-picker,
  .rw-state-disabled > .rw-widget-picker {
    background-color: #eeeeee; }

.rw-multiselect > .rw-widget-picker {
  height: auto; }

.rw-select {
  cursor: pointer; }
  .rw-select > * {
    width: 1.9em;
    height: 100%; }
  .rw-state-readonly .rw-select,
  .rw-state-disabled .rw-select {
    cursor: not-allowed; }

.rw-select-bordered {
  cursor: pointer;
  border: none;
  border-left: #ccc 1px solid; }
  .rw-select-bordered:hover, .rw-select-bordered:active {
    background-color: #e6e6e6; }
  .rw-select-bordered:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .rw-state-disabled .rw-select-bordered, .rw-state-disabled .rw-select-bordered:hover, .rw-state-disabled .rw-select-bordered:active,
  .rw-state-readonly .rw-select-bordered,
  .rw-state-readonly .rw-select-bordered:hover,
  .rw-state-readonly .rw-select-bordered:active,
  fieldset[disabled] .rw-select-bordered,
  fieldset[disabled] .rw-select-bordered:hover,
  fieldset[disabled] .rw-select-bordered:active {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    box-shadow: none; }
  .rw-rtl .rw-select-bordered {
    border-right: #ccc 1px solid;
    border-left: none; }

.rw-rtl {
  direction: rtl; }


.rw-input-reset,
.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  outline: 0; }
  .rw-input-reset::-moz-placeholder, .rw-input::-moz-placeholder, .rw-dropdown-list-autofill::-moz-placeholder, .rw-filter-input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .rw-input-reset:-ms-input-placeholder, .rw-input:-ms-input-placeholder, .rw-dropdown-list-autofill:-ms-input-placeholder, .rw-filter-input:-ms-input-placeholder {
    color: #999; }
  .rw-input-reset::-webkit-input-placeholder, .rw-input::-webkit-input-placeholder, .rw-dropdown-list-autofill::-webkit-input-placeholder, .rw-filter-input::-webkit-input-placeholder {
    color: #999; }

.rw-input, .rw-dropdown-list-autofill, .rw-filter-input {
  color: #555555;
  padding: 0 0.857em;
  background-color: #fff; }
  .rw-input[type="text"]::-ms-clear, .rw-dropdown-list-autofill[type="text"]::-ms-clear, .rw-filter-input[type="text"]::-ms-clear {
    display: none; }
  .rw-input[disabled], .rw-dropdown-list-autofill[disabled], .rw-filter-input[disabled],
  fieldset[disabled] .rw-input,
  fieldset[disabled] .rw-dropdown-list-autofill,
  fieldset[disabled] .rw-filter-input {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    background-color: #eeeeee;
    border-color: #ccc; }
  .rw-input[readonly], .rw-dropdown-list-autofill[readonly], .rw-filter-input[readonly] {
    cursor: not-allowed; }

.rw-i.rw-loading {
  display: block;
  background: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///zMzM87OzmdnZzMzM4GBgZqamqenpyH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==) no-repeat center;
  min-width: 16px;
  width: 1.9em;
  height: 16px; }
  .rw-i.rw-loading:before {
    content: ""; }

.rw-placeholder {
  color: #999; }

.rw-detect-autofill:-webkit-autofill {
  -webkit-animation-name: react-widgets-autofill-start;
          animation-name: react-widgets-autofill-start;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s; }

.rw-detect-autofill:not(:-webkit-autofill) {
  -webkit-animation-name: react-widgets-autofill-cancel;
          animation-name: react-widgets-autofill-cancel; }

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill,
.rw-dropdown-list-autofill:-webkit-autofill,
.rw-filter-input:-webkit-autofill {
  background-color: #faffbd !important;
  background-image: none !important;
  color: black !important; }

.rw-widget-input, .rw-filter-input {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.rw-widget-input.rw-state-focus, .rw-state-focus.rw-filter-input {
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6), inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.rw-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  outline: 0;
  overflow: auto;
  max-height: 200px; }

.rw-list-option {
  -webkit-user-select: none;
          user-select: none;
  color: #333333;
  cursor: pointer;
  border: 1px solid transparent; }
  .rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover {
    background-color: transparent;
    border-color: #66afe9;
    color: #333333; }
  .rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #333333; }
  .rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
    background-color: #337ab7;
    border-color: #337ab7;
    color: white; }
  fieldset[disabled] .rw-list-option, .rw-list-option.rw-state-disabled, .rw-list-option.rw-state-readonly {
    box-shadow: none;
    cursor: not-allowed;
    color: #999999;
    opacity: .7; }
    fieldset[disabled] .rw-list-option:hover, .rw-list-option.rw-state-disabled:hover, .rw-list-option.rw-state-readonly:hover {
      background: none;
      border-color: transparent; }

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
  padding: 0.143em 0.75em;
  outline: 0; }

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px; }

.rw-list-option-create {
  border-top: 1px #ccc solid; }

.rw-dropdown-list-autofill {
  padding: 0; }

.rw-dropdown-list-input {
  background-color: transparent;
  vertical-align: middle;
  padding-right: 0;
  /* ellipsis */ }
  .rw-dropdown-list-input {
    max-width: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .rw-rtl .rw-dropdown-list-input {
    padding-right: 0.857em;
    padding-left: 0; }

.rw-filter-input {
  position: relative;
  margin: 4px;
  padding-right: 0; }
  .rw-filter-input .rw-rtl {
    padding-right: 0.857em;
    padding-left: 0; }
  .rw-filter-input .rw-select,
  .rw-filter-input .rw-btn {
    opacity: 0.75;
    cursor: text; }
  .rw-filter-input > .rw-select, .rw-filter-input > .rw-select:active, .rw-filter-input > .rw-select:hover {
    background: none;
    cursor: initial;
    box-shadow: none; }

.rw-number-picker .rw-btn {
  cursor: pointer;
  height: calc(1.2145em - 1px);
  /** ie8 fallback **/
  margin-top: -1px\9;
  height: 1.2145em\9;
  /** --- **/
  line-height: 1.2145em;
  line-height: calc(1.2145em - 1px);
  display: block;
  border: none; }
  .rw-number-picker .rw-btn:hover, .rw-number-picker .rw-btn:active {
    background-color: #e6e6e6; }
  .rw-number-picker .rw-btn:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .rw-state-disabled .rw-number-picker .rw-btn, .rw-state-disabled .rw-number-picker .rw-btn:hover, .rw-state-disabled .rw-number-picker .rw-btn:active,
  .rw-state-readonly .rw-number-picker .rw-btn,
  .rw-state-readonly .rw-number-picker .rw-btn:hover,
  .rw-state-readonly .rw-number-picker .rw-btn:active,
  fieldset[disabled] .rw-number-picker .rw-btn,
  fieldset[disabled] .rw-number-picker .rw-btn:hover,
  fieldset[disabled] .rw-number-picker .rw-btn:active {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    box-shadow: none; }

.rw-number-picker .rw-select {
  vertical-align: middle; }
  .rw-number-picker .rw-select, .rw-number-picker .rw-select:hover, .rw-number-picker .rw-select:active {
    box-shadow: none; }

.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 18em; }

.rw-calendar {
  border-radius: 4px;
  background-color: #fff;
  border: #ccc 1px solid;
  overflow: hidden; }
  .rw-calendar.rw-popup {
    border-color: #ccc; }

.rw-calendar-now {
  font-weight: bold; }

.rw-calendar-btn-left,
.rw-calendar-btn-right {
  width: 12.5%; }

.rw-calendar-btn-view {
  width: 75%; }

.rw-calendar-footer {
  border-top: 1px solid #ccc; }

.rw-calendar-grid {
  outline: none;
  height: 200 / 14em;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #fff; }

.rw-head-cell {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 0.25em; }

.rw-cell {
  color: #333333;
  border-radius: 4px;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.25em; }
  .rw-cell:hover {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #333333; }
  .rw-cell.rw-state-focus, .rw-cell.rw-state-focus:hover {
    background-color: transparent;
    border-color: #66afe9;
    color: #333333; }
  .rw-cell.rw-state-selected, .rw-cell.rw-state-selected:hover {
    background-color: #337ab7;
    border-color: #337ab7;
    color: white; }
  .rw-cell.rw-state-disabled {
    color: #999999;
    opacity: .7; }
    .rw-cell.rw-state-disabled:hover {
      background: none;
      border-color: transparent; }
  .rw-calendar-month .rw-cell {
    text-align: center; }

.rw-cell-off-range {
  color: #999999; }

.rw-calendar-transition-group {
  position: relative; }

.rw-calendar-transition {
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  overflow: hidden; }

.rw-calendar-transition-top {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.rw-calendar-transition-bottom {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.rw-calendar-transition-right {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.rw-calendar-transition-left {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.rw-calendar-transition-entering.rw-calendar-transition-top, .rw-calendar-transition-entering.rw-calendar-transition-bottom,
.rw-calendar-transition-entered.rw-calendar-transition-top,
.rw-calendar-transition-entered.rw-calendar-transition-bottom {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.rw-calendar-transition-entering.rw-calendar-transition-right, .rw-calendar-transition-entering.rw-calendar-transition-left,
.rw-calendar-transition-entered.rw-calendar-transition-right,
.rw-calendar-transition-entered.rw-calendar-transition-left {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.rw-calendar-transition-exiting.rw-calendar-transition-top {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.rw-calendar-transition-exiting.rw-calendar-transition-bottom {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.rw-calendar-transition-exiting.rw-calendar-transition-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.rw-calendar-transition-exiting.rw-calendar-transition-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.rw-select-list {
  overflow: auto;
  position: relative; }
  .rw-select-list .rw-list {
    max-height: none;
    font-size: 1em; }

.rw-select-list-label {
  display: block;
  position: relative;
  font-weight: normal;
  cursor: inherit;
  padding-left: 20px;
  margin: 0; }
  .rw-rtl .rw-select-list-label {
    padding-left: 0;
    padding-right: 20px; }

input.rw-select-list-input {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 0.1em\9;
  margin: 0;
  line-height: normal;
  cursor: inherit; }
  .rw-rtl input.rw-select-list-input {
    left: auto;
    right: 0; }

.rw-loading-mask {
  content: '';
  background: url(/static/media/loader-big.a770b679.gif) no-repeat center;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  opacity: 0.7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.rw-multiselect {
  cursor: text; }
  .rw-multiselect .rw-input-reset, .rw-multiselect .rw-input, .rw-multiselect .rw-dropdown-list-autofill, .rw-multiselect .rw-filter-input {
    height: calc(2.429em - 2px);
    /** ie8 fallback **/
    margin-top: -2px\9;
    height: 2.429em\9;
    /** --- **/
    border-width: 0;
    width: auto;
    max-width: 100%;
    padding: 0 0.857em; }
  .rw-multiselect .rw-select {
    vertical-align: middle; }
    .rw-multiselect .rw-select, .rw-multiselect .rw-select:hover, .rw-multiselect .rw-select:active {
      box-shadow: none;
      background: none; }

.rw-multiselect-taglist {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: 0;
  outline: none; }

.rw-multiselect-tag {
  display: inline-table;
  color: inherit;
  padding: 0 0.35em 0 0.35em;
  margin-left: calc(0.27933em - 1px);
  margin-top: 0.27933em;
  margin-top: calc(0.27933em - 1px);
  height: 1.87033em;
  border-radius: 3px;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  cursor: default;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
  max-width: 100%; }
  .rw-multiselect-tag > * {
    display: table-cell;
    vertical-align: middle;
    height: 100%; }
  .rw-rtl .rw-multiselect-tag {
    margin-left: 0;
    margin-right: calc(0.27933em - 1px);
    padding: 0 0.35em 0 0.35em; }
  .rw-multiselect-tag.rw-state-focus, .rw-multiselect-tag.rw-state-focus:hover {
    background-color: transparent;
    border-color: #66afe9;
    color: #333333; }
  .rw-multiselect-tag.rw-state-readonly, .rw-multiselect-tag.rw-state-disabled,
  .rw-state-readonly .rw-multiselect-tag,
  .rw-state-disabled .rw-multiselect-tag,
  fieldset[disabled] .rw-multiselect-tag {
    cursor: not-allowed; }
  .rw-multiselect-tag.rw-state-disabled,
  .rw-state-disabled .rw-multiselect-tag,
  fieldset[disabled] .rw-multiselect-tag {
    opacity: 0.65; }
  fieldset[disabled] .rw-multiselect-tag {
    box-shadow: none;
    cursor: not-allowed; }

.rw-multiselect-tag-btn {
  color: inherit;
  margin-left: 0.25em; }
  .rw-rtl .rw-multiselect-tag-btn {
    margin-left: 0;
    margin-right: 0.25em; }

.rw-autocomplete .rw-select {
  position: absolute;
  display: block;
  width: auto;
  top: 0;
  bottom: 0;
  right: 0; }

.rw-popup-container {
  position: absolute;
  z-index: 1005;
  top: 100%;
  left: -6px;
  right: -6px; }
  .rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%; }
  .rw-state-focus .rw-popup-container {
    z-index: 1006; }

.rw-popup-transition {
  width: 100%;
  margin-bottom: 6px;
  padding: 0 6px; }
  .rw-dropup > .rw-popup-transition {
    margin-bottom: 0;
    margin-top: 6px; }

.rw-popup {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  border: #ccc 1px solid;
  background: #fff; }
  .rw-dropup .rw-popup {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2); }

.rw-popup-transition {
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms; }

.rw-popup-transition-entering {
  overflow: hidden; }
  .rw-popup-transition-entering .rw-popup-transition {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-timing-function: ease-out; }

.rw-popup-transition-exiting .rw-popup-transition {
  transition-timing-function: ease-in; }

.rw-popup-transition-exiting,
.rw-popup-transition-exited {
  overflow: hidden; }
  .rw-popup-transition-exiting .rw-popup-transition,
  .rw-popup-transition-exited .rw-popup-transition {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  .rw-popup-transition-exiting.rw-dropup .rw-popup-transition,
  .rw-popup-transition-exited.rw-dropup .rw-popup-transition {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }

.rw-popup-transition-exited {
  display: none; }

.rw-state-disabled {
  box-shadow: none;
  cursor: not-allowed; }

/**
* General Uppy styles that apply to everything inside the .Uppy container
*/
.uppy-Root {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  position: relative;
  color: #333; }

.uppy-Root *, .uppy-Root *:before, .uppy-Root *:after {
  box-sizing: inherit; }

.uppy-Root [hidden] {
  display: none; }

.UppyIcon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  /* no !important */
  display: inline-block;
  overflow: hidden; }

.UppyIcon--svg-baseline {
  bottom: -0.125em;
  position: relative; }

.uppy-u-reset {
  -webkit-appearance: none;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: none;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  box-shadow: none;
  clear: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  -webkit-transform: none;
          transform: none;
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-style: flat;
          transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  z-index: auto; }

.uppy-c-textInput {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px 8px;
  background-color: #fff; }

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px; }

.uppy-c-textInput:focus {
  border-color: rgba(34, 117, 215, 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15); }

.uppy-c-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  transition: background-color 0.3s;
  -webkit-user-select: none;
          user-select: none; }

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.uppy-c-btn::-moz-focus-inner {
  border: 0; }

.uppy-c-btn-primary {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #2275d7;
  color: #fff; }

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px; }

.uppy-c-btn-primary:hover {
  background-color: #1b5dab; }

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4); }

.uppy-c-btn-link {
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: transparent;
  color: #525252; }

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px; }

.uppy-c-btn-link:hover {
  color: #333; }

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25); }

.uppy-c-btn--small {
  font-size: 0.9em;
  padding: 7px 16px;
  border-radius: 2px; }

.uppy-size--md .uppy-c-btn--small {
  padding: 8px 10px;
  border-radius: 2px; }

.uppy-Informer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  transition: all 250ms ease-in;
  z-index: 1005; }

.uppy-Informer[aria-hidden=true] {
  opacity: 0;
  -webkit-transform: translateY(350%);
          transform: translateY(350%);
  transition: all 300ms ease-in;
  z-index: -1000; }

.uppy-Informer p {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  padding: 6px 15px;
  background-color: #757575;
  /* no !important */
  color: #fff;
  border-radius: 18px;
  max-width: 90%; }
  .uppy-size--md .uppy-Informer p {
    font-size: 14px;
    line-height: 1.3;
    max-width: 500px;
    padding: 10px 20px; }

.uppy-Informer span {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #525252;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  top: -1px;
  left: 3px;
  font-size: 10px;
  margin-left: -1px; }

.uppy-Informer span:hover {
  cursor: help; }

.uppy-Informer span:after {
  line-height: 1.3;
  word-wrap: break-word; }

/* -------------------------------------------------------------------
  Microtip

  Modern, lightweight css-only tooltips
  Just 1kb minified and gzipped

  @author Ghosh
  @package Microtip

----------------------------------------------------------------------
  1. Base Styles
  2. Direction Modifiers
  3. Position Modifiers
--------------------------------------------------------------------*/
/* ------------------------------------------------
  [1] Base Styles
-------------------------------------------------*/
.uppy-Root [aria-label][role~="tooltip"] {
  /* no important */
  position: relative; }

.uppy-Root [aria-label][role~="tooltip"]::before,
.uppy-Root [aria-label][role~="tooltip"]::after {
  /* no important */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-in-out 0s;
  transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  -webkit-transform-origin: top;
          transform-origin: top; }

.uppy-Root [aria-label][role~="tooltip"]::before {
  /* no important */
  background-size: 100% auto !important;
  content: ""; }

.uppy-Root [aria-label][role~="tooltip"]::after {
  /* no important */
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #ffffff;
  content: attr(aria-label);
  font-size: 13px;
  font-size: var(--microtip-font-size, 13px);
  font-weight: normal;
  font-weight: var(--microtip-font-weight, normal);
  text-transform: none;
  text-transform: var(--microtip-text-transform, none);
  padding: .5em 1em;
  white-space: nowrap;
  box-sizing: content-box; }

.uppy-Root [aria-label][role~="tooltip"]:hover::before,
.uppy-Root [aria-label][role~="tooltip"]:hover::after,
.uppy-Root [aria-label][role~="tooltip"]:focus::before,
.uppy-Root [aria-label][role~="tooltip"]:focus::after {
  /* no important */
  opacity: 1;
  pointer-events: auto; }

/* ------------------------------------------------
  [2] Position Modifiers
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-bottom: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  /* no important */
  margin-bottom: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover::before {
  /* no important */
  -webkit-transform: translate3d(-50%, -5px, 0);
          transform: translate3d(-50%, -5px, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(-50%, -5px, 0);
          transform: translate3d(-50%, -5px, 0); }

/* ------------------------------------------------
  [2.1] Top Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
          transform: translate3d(calc(-100% + 16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), -5px, 0);
          transform: translate3d(calc(-100% + 16px), -5px, 0); }

/* ------------------------------------------------
  [2.2] Top Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
          transform: translate3d(calc(0% + -16px), 0, 0);
  bottom: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), -5px, 0);
          transform: translate3d(calc(0% + -16px), -5px, 0); }

/* ------------------------------------------------
  [2.3] Bottom
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  /* no important */
  margin-top: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
  /* no important */
  -webkit-transform: translate3d(-50%, -10px, 0);
          transform: translate3d(-50%, -10px, 0);
  bottom: auto;
  left: 50%;
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover::before {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
  /* no important */
  -webkit-transform: translate3d(-50%, -10px, 0);
          transform: translate3d(-50%, -10px, 0);
  top: 100%;
  left: 50%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

/* ------------------------------------------------
  [2.4] Bottom Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), -10px, 0);
          transform: translate3d(calc(-100% + 16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
          transform: translate3d(calc(-100% + 16px), 0, 0); }

/* ------------------------------------------------
  [2.5] Bottom Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), -10px, 0);
          transform: translate3d(calc(0% + -16px), -10px, 0);
  top: 100%; }

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
          transform: translate3d(calc(0% + -16px), 0, 0); }

/* ------------------------------------------------
  [2.6] Left
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  /* no important */
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  -webkit-transform: translate3d(10px, -50%, 0);
          transform: translate3d(10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
  /* no important */
  margin-right: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [2.7] Right
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  /* no important */
  bottom: auto;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(-10px, -50%, 0);
          transform: translate3d(-10px, -50%, 0); }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
  /* no important */
  margin-left: 11px; }

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::after {
  /* no important */
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

/* ------------------------------------------------
  [3] Size
-------------------------------------------------*/
.uppy-Root [role~="tooltip"][data-microtip-size="small"]::after {
  /* no important */
  white-space: initial;
  width: 80px; }

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]::after {
  /* no important */
  white-space: initial;
  width: 150px; }

.uppy-Root [role~="tooltip"][data-microtip-size="large"]::after {
  /* no important */
  white-space: initial;
  width: 260px; }

.uppy-StatusBar {
  display: flex;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: #fff;
  z-index: 1001;
  transition: height .2s; }

.uppy-size--md .uppy-StatusBar {
  height: 46px; }

.uppy-StatusBar:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea; }

.uppy-StatusBar[aria-hidden=true] {
  overflow-y: hidden;
  height: 0; }

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240; }

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437; }

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240; }

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437; }

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea; }

.uppy-StatusBar-progress {
  background-color: #2275d7;
  height: 2px;
  position: absolute;
  z-index: 1001;
  transition: background-color, width .3s ease-out; }
  .uppy-StatusBar-progress.is-indeterminate {
    background-size: 64px 64px;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
    -webkit-animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
            animation: uppy-StatusBar-ProgressStripes 1s linear infinite; }

@-webkit-keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 64px 0; } }

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 64px 0; } }

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none; }

.uppy-StatusBar-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1002;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  height: 100%; }
  .uppy-size--md .uppy-StatusBar-content {
    padding-left: 15px; }

.uppy-StatusBar-status {
  line-height: 1.4;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 170px;
  overflow-x: hidden;
  padding-right: 0.3em; }
  .uppy-size--md .uppy-StatusBar-status {
    max-width: 400px; }

.uppy-StatusBar-statusPrimary {
  font-weight: 500; }

.uppy-StatusBar-statusSecondary {
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
  color: #757575;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden; }

.uppy-StatusBar-statusSecondaryHint {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  line-height: 1; }
  .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
    margin-right: 8px; }

.uppy-StatusBar-statusIndicator {
  position: relative;
  top: 1px;
  color: #525252;
  margin-right: 7px; }
  .uppy-StatusBar-statusIndicator svg {
    vertical-align: text-bottom; }

.uppy-StatusBar-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  z-index: 1004; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  position: static;
  padding: 0 15px;
  background-color: #fafafa; }

.uppy-StatusBar-actionCircleBtn {
  line-height: 1;
  cursor: pointer;
  padding: 3px;
  opacity: 0.9; }
  .uppy-StatusBar-actionCircleBtn:hover {
    opacity: 1; }

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom; }

.uppy-StatusBar-actionBtn {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: inherit;
  color: #2275d7; }
  .uppy-size--md .uppy-StatusBar-actionBtn {
    font-size: 11px; }

.uppy-StatusBar-actionBtn--retry {
  height: 16px;
  border-radius: 8px;
  margin-right: 6px;
  background-color: #ff4b23;
  line-height: 1;
  color: #fff;
  padding: 1px 6px 3px 18px;
  position: relative; }
  .uppy-StatusBar-actionBtn--retry:hover {
    background-color: #f92d00; }
  .uppy-StatusBar-actionBtn--retry svg {
    position: absolute;
    top: 3px;
    left: 6px; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  font-size: 14px;
  width: 100%;
  padding: 15px 10px;
  color: #fff;
  background-color: #1bb240;
  line-height: 1; }

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #148630; }

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  padding: 13px 22px;
  width: auto; }

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  background-color: transparent;
  color: #2275d7; }

.uppy-StatusBar-details {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #939393;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 2px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  cursor: help; }

.uppy-StatusBar-details:after {
  line-height: 1.3;
  word-wrap: break-word; }

.uppy-StatusBar-spinner {
  -webkit-animation-name: uppy-StatusBar-spinnerAnimation;
          animation-name: uppy-StatusBar-spinnerAnimation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  margin-right: 10px;
  fill: #2275d7; }

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623; }

@-webkit-keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.uppy-DashboardContent-panelBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1; }

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  flex: 1 1;
  color: #939393; }

.uppy-Provider-empty {
  color: #939393; }

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px; }

.uppy-Provider-authTitle {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 15px;
  max-width: 500px;
  text-align: center;
  color: #757575; }
  .uppy-size--md .uppy-Provider-authTitle {
    font-size: 20px; }

.uppy-Provider-breadcrumbs {
  flex: 1 1;
  color: #525252;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left; }
  .uppy-size--md .uppy-Provider-breadcrumbs {
    margin-bottom: 0; }

.uppy-Provider-breadcrumbsIcon {
  display: inline-block;
  color: #525252;
  vertical-align: bottom;
  margin-right: 8px;
  line-height: 1; }

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252; }

.uppy-Provider-breadcrumbs button {
  display: inline-block;
  line-height: inherit; }

.uppy-Provider-breadcrumbs button:hover {
  text-decoration: underline;
  cursor: pointer; }

.uppy-ProviderBrowser {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-size: 14px;
  font-weight: 400;
  height: 100%; }

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0;
  font-weight: 500;
  color: #333; }

.uppy-ProviderBrowser-user:after {
  content: '\B7';
  position: relative;
  left: 4px;
  color: #939393;
  font-weight: normal; }

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative; }

.uppy-ProviderBrowser-headerBar {
  padding: 12px 15px;
  background-color: #fafafa;
  z-index: 1001;
  color: #757575;
  line-height: 1.4;
  font-size: 12px; }
  .uppy-size--md .uppy-ProviderBrowser-headerBar {
    display: flex;
    align-items: center;
    padding: 12px 15px; }

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  display: block;
  justify-content: center; }

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  flex: none;
  display: inline-block;
  vertical-align: middle; }

.uppy-ProviderBrowser-search {
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center; }

.uppy-ProviderBrowser-searchIcon {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 16px;
  z-index: 1002;
  color: #bbb; }

.uppy-ProviderBrowser-searchInput {
  width: 100%;
  height: 30px;
  background-color: transparent;
  outline: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  line-height: 1.4;
  border: 0;
  margin: 0 8px;
  padding-left: 27px;
  z-index: 1001;
  border-radius: 4px; }

.uppy-ProviderBrowser-searchInput:focus {
  outline: 0;
  background-color: #f4f4f4; }

.uppy-ProviderBrowser-searchClose {
  position: absolute;
  width: 22px;
  height: 22px;
  padding: 6px;
  right: 12px;
  top: 4px;
  z-index: 1002;
  color: #939393;
  cursor: pointer; }
  .uppy-ProviderBrowser-searchClose:hover {
    color: #757575; }

.uppy-ProviderBrowser-searchClose svg {
  vertical-align: text-top; }

.uppy-ProviderBrowser-searchInput::-webkit-input-placeholder {
  color: #939393;
  opacity: 1; }

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1; }

.uppy-ProviderBrowser-userLogout {
  cursor: pointer;
  line-height: inherit;
  color: #2275d7; }
  .uppy-ProviderBrowser-userLogout:hover {
    text-decoration: underline; }

.uppy-ProviderBrowser-body {
  flex: 1 1;
  position: relative; }

.uppy-ProviderBrowser-list {
  flex: 1 1;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-spacing: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0; }

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px; }

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem {
    display: flex;
    padding: 7px 15px;
    margin: 0; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
    vertical-align: middle; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox label:before {
    border-color: #cfcfcf; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox input:checked + label:before {
    border-color: #2275d7; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    line-height: 1.4; }
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,
  .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
    vertical-align: middle;
    margin-right: 8px;
    max-width: 20px;
    max-height: 20px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowser-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowser-list:after {
  content: '';
  flex: auto; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem {
  display: inline-block;
  width: 50%;
  position: relative;
  margin: 0; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem:before {
  content: '';
  padding-top: 100%;
  display: block; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner {
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  text-align: center; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.9); }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem img,
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem--selected svg {
  opacity: 0.85; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(147, 147, 147, 0.3); }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(0, 0, 0, 0.7);
  width: 30%;
  height: 30%; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 16px;
  right: 16px;
  display: block;
  margin-right: 0;
  z-index: 1002; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox label:before {
  background-color: #2275d7;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  top: 0; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox label:after {
  width: 12px;
  height: 7px;
  left: 7px;
  top: 8px; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox input + label {
  opacity: 0; }

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox input:checked + label {
  opacity: 1; }

.uppy-size--md .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem {
  width: 33.3333%; }

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem {
  width: 25%; }

.uppy-ProviderBrowserItem-checkbox input {
  opacity: 0; }

.uppy-ProviderBrowserItem-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 15px; }

.uppy-ProviderBrowserItem-checkbox label {
  display: block; }

.uppy-ProviderBrowserItem-checkbox label::before,
.uppy-ProviderBrowserItem-checkbox label::after {
  position: absolute;
  cursor: pointer; }

.uppy-ProviderBrowserItem-checkbox label:before {
  content: "";
  display: inline-block;
  height: 17px;
  width: 17px;
  top: 2px;
  border: 1px solid #2275d7;
  background-color: #fff;
  border-radius: 3px; }

.uppy-ProviderBrowserItem-checkbox label:after {
  content: '';
  display: inline-block;
  height: 5px;
  width: 9px;
  left: 4px;
  top: 7px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.uppy-ProviderBrowserItem-checkbox input + label::after {
  content: none; }

.uppy-ProviderBrowserItem-checkbox input:checked + label::after {
  content: ''; }

.uppy-ProviderBrowserItem-checkbox input:checked + label::before {
  background-color: #2275d7; }

.uppy-ProviderBrowserItem-checkbox input:focus + label::before {
  outline: #3b99fc auto 5px; }

.uppy-ProviderBrowser-footer {
  display: flex;
  align-items: center;
  background: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea;
  padding: 0 15px; }
  .uppy-ProviderBrowser-footer button {
    margin-right: 8px; }

.uppy-transition-slideDownUp-enter {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0); }

.uppy-Dashboard--modal {
  z-index: 1001; }

.uppy-Dashboard--modal[aria-hidden=true] {
  display: none; }

@-webkit-keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; } }

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-50%, -70%, 0);
            transform: translate3d(-50%, -70%, 0);
    opacity: 0; } }

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; } }

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, -20%, 0);
            transform: translate3d(0, -20%, 0);
    opacity: 0; } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  -webkit-animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
      -webkit-animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
              animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  -webkit-animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  -webkit-animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
      -webkit-animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
              animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  -webkit-animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.uppy-Dashboard-isFixed {
  overflow: hidden;
  height: 100vh; }

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001; }

.uppy-Dashboard-inner {
  position: relative;
  background-color: #fafafa;
  max-width: 100%;
  /* no !important */
  max-height: 100%;
  /* no !important */
  min-height: 450px;
  outline: none;
  border: 1px solid #eaeaea;
  border-radius: 5px; }
  .uppy-size--md .uppy-Dashboard-inner {
    min-height: auto; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard-inner {
      width: 750px;
      /* no !important */
      height: 550px;
      /* no !important */ } }
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1002; }

.uppy-Dashboard-innerWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  opacity: 0; }

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1; }

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  position: fixed;
  top: 35px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: none; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal .uppy-Dashboard-inner {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15); } }

.uppy-Dashboard-close {
  display: block;
  position: absolute;
  top: -33px;
  right: -2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  font-size: 27px;
  z-index: 1005; }
  @media only screen and (min-width: 820px) {
    .uppy-Dashboard-close {
      font-size: 35px;
      top: -10px;
      right: -35px; } }

.uppy-DashboardAddFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;
  flex: 1 1;
  margin: 7px;
  border: 1px dashed #dfdfdf;
  border-radius: 3px; }
  .uppy-Dashboard-AddFilesPanel .uppy-DashboardAddFiles {
    border: none; }
  .uppy-Dashboard--modal .uppy-DashboardAddFiles {
    border-color: #cfcfcf; }

.uppy-DashboardTabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }
  .uppy-size--md .uppy-DashboardTabs {
    align-items: center; }

.uppy-DashboardTabs-title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #525252; }
  .uppy-size--md .uppy-DashboardTabs-title {
    font-size: 16px;
    line-height: 40px; }

.uppy-DashboardAddFiles-info {
  padding-top: 15px;
  padding-bottom: 15px; }
  .uppy-size--md .uppy-DashboardAddFiles-info {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 0; }

.uppy-Dashboard-browse {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  color: rgba(34, 117, 215, 0.9); }
  .uppy-Dashboard-browse:hover {
    text-decoration: underline; }

.uppy-Dashboard-browse:focus {
  outline: none;
  border-bottom: 2px solid #2275d7; }

.uppy-DashboardTabs-list {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
  padding: 2px 0; }
  .uppy-size--md .uppy-DashboardTabs-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
    overflow-x: initial;
    margin-top: 15px;
    padding-top: 0; }

.uppy-DashboardTab {
  width: 100%;
  display: inline-block;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding: 0px 2px; }
  .uppy-size--md .uppy-DashboardTab {
    width: initial;
    margin-bottom: 20px;
    border-bottom: none;
    padding: 0; }

.uppy-DashboardTab-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
       appearance: none;
  color: #525252;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  line-height: 1;
  text-align: center; }
  .uppy-size--md .uppy-DashboardTab-btn {
    width: 86px;
    margin-right: 1px;
    flex-direction: column;
    padding: 10px 3px;
    border-radius: 5px; }

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0; }

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6; }

.uppy-DashboardTab-btn:active,
.uppy-DashboardTab-btn:focus {
  background-color: #ebeef2;
  outline: none; }

.uppy-DashboardTab-btn svg {
  margin-right: 10px; }
  .uppy-size--md .uppy-DashboardTab-btn svg {
    margin-right: 0; }

.uppy-DashboardTab-btn svg {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: text-top;
  overflow: hidden;
  transition: -webkit-transform ease-in-out .15s;
  transition: transform ease-in-out .15s;
  transition: transform ease-in-out .15s, -webkit-transform ease-in-out .15s; }

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 500; }
  .uppy-size--md .uppy-DashboardTab-name {
    font-size: 11px;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 0; }

.uppy-DashboardTab svg {
  width: 18px;
  height: 18px;
  vertical-align: middle; }
  .uppy-size--md .uppy-DashboardTab svg {
    width: 27px;
    height: 27px; }

.uppy-Dashboard-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.uppy-DashboardContent-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  z-index: 1004;
  background-color: #fafafa;
  padding: 0 10px; }
  .uppy-size--md .uppy-DashboardContent-bar {
    height: 50px;
    padding: 0 15px; }

.uppy-DashboardContent-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  font-weight: 500;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  margin: auto; }
  .uppy-size--md .uppy-DashboardContent-title {
    font-size: 14px;
    line-height: 50px;
    max-width: 300px; }

.uppy-DashboardContent-back {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #2275d7;
  padding: 7px 6px;
  margin-left: -6px;
  border-radius: 3px; }
  .uppy-DashboardContent-back:hover {
    color: #1a58a2; }
  .uppy-size--md .uppy-DashboardContent-back {
    font-size: 14px; }

.uppy-DashboardContent-addMore {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  color: #2275d7;
  width: 27px;
  height: 27px;
  padding: 6px;
  margin-right: -6px;
  border-radius: 3px; }
  .uppy-DashboardContent-addMore:hover {
    color: #1a58a2; }

.uppy-DashboardContent-addMore svg {
  vertical-align: text-top; }

.uppy-DashboardContent-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: whitesmoke;
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1 1; }

.uppy-Dashboard-AddFilesPanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fafafa;
  background: linear-gradient(0deg, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: flex;
  flex-direction: column; }

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  -webkit-filter: blur(2px);
          filter: blur(2px); }

.uppy-Dashboard-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12%; }

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.uppy-Dashboard-filesContainer {
  position: relative;
  overflow-y: hidden;
  margin: 0;
  flex: 1 1; }
  .uppy-Dashboard-filesContainer:after {
    content: '';
    display: table;
    clear: both; }

.uppy-Dashboard-files {
  margin: 0;
  padding: 0 0 10px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1; }

.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px; }

.uppy-Dashboard-dropFilesHereHint {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  padding-top: 90px;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  z-index: 2000;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: #707070;
  font-size: 16px; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators {
  opacity: 0.15; }

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardAddFiles {
  opacity: 0.03; }

.uppy-Dashboard-dropFilesTitle {
  max-width: 300px;
  text-align: center;
  font-size: 16px;
  line-height: 1.45;
  font-weight: 400;
  color: #525252;
  margin: auto;
  padding: 0 15px;
  padding-top: 20px; }
  .uppy-size--md .uppy-Dashboard-dropFilesTitle {
    max-width: 400px;
    font-size: 27px; }

.uppy-Dashboard-note {
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  color: #757575;
  max-width: 350px;
  margin: auto;
  padding: 0 15px; }
  .uppy-size--md .uppy-Dashboard-note {
    font-size: 16px;
    line-height: 1.35;
    max-width: 600px; }

a.uppy-Dashboard-poweredBy {
  display: inline-block;
  text-align: center;
  font-size: 11px;
  color: #939393;
  text-decoration: none;
  margin-top: 8px; }

.uppy-Dashboard-poweredByIcon {
  stroke: #939393;
  fill: none;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
  opacity: 0.9;
  vertical-align: text-top; }

.uppy-DashboardItem {
  list-style: none;
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  padding-left: 10px; }
  .uppy-size--md .uppy-DashboardItem {
    float: left;
    margin: 5px 15px;
    width: calc(33.333% - 15px - 15px);
    height: 170px;
    flex-direction: column;
    background-color: initial;
    border: 0;
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 0; }
  .uppy-size--lg .uppy-DashboardItem {
    width: calc(25% - 15px - 15px);
    height: 190px; }
  .uppy-size--xl .uppy-DashboardItem {
    width: calc(20% - 15px - 15px);
    height: 210px; }

.uppy-DashboardItem-preview {
  width: 50px;
  height: 50px;
  border-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .uppy-size--md .uppy-DashboardItem-preview {
    width: 100%;
    height: 100px;
    border: 0; }
  .uppy-size--lg .uppy-DashboardItem-preview {
    height: 120px; }
  .uppy-size--xl .uppy-DashboardItem-preview {
    height: 140px; }

.uppy-DashboardItem-previewLink {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1002; }

.uppy-DashboardItem-sourceIcon {
  display: inline-block;
  vertical-align: bottom;
  color: #939393; }

.uppy-DashboardItem-sourceIcon svg,
.uppy-DashboardItem-sourceIcon svg * {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: text-bottom;
  overflow: hidden;
  fill: currentColor;
  width: 11px;
  height: 12px; }

.uppy-DashboardItem-previewInnerWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 3px; }
  .uppy-size--md .uppy-DashboardItem-previewInnerWrap {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.uppy-DashboardItem-previewInnerWrap:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
  z-index: 1001; }

.uppy-DashboardItem-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.uppy-DashboardItem-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative; }

.uppy-DashboardItem-previewIconBg {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
          filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px); }

.uppy-DashboardItem-previewIcon {
  width: 25px;
  height: 25px;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .uppy-size--md .uppy-DashboardItem-previewIcon {
    width: 38px;
    height: 38px; }
  .uppy-DashboardItem-previewIcon svg {
    width: 100%;
    height: 100%; }

.uppy-DashboardItem-previewType {
  position: absolute;
  bottom: 14px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  color: #525252;
  z-index: 100;
  -webkit-user-select: none;
          user-select: none; }

.uppy-DashboardItem-info {
  padding-left: 15px;
  position: relative;
  max-width: 65%; }
  .uppy-size--md .uppy-DashboardItem-info {
    width: 100%;
    max-width: 100%;
    flex: 1 1;
    padding: 8px 0 0;
    border-top: 0; }

.uppy-DashboardItem-name {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }
  .uppy-size--md .uppy-DashboardItem-name {
    word-break: break-all;
    white-space: normal;
    overflow: initial; }

.uppy-DashboardItem-name a {
  text-decoration: none;
  color: #333; }

.uppy-DashboardItem-status {
  font-size: 11px;
  line-height: 1.3;
  font-weight: normal;
  color: #757575;
  margin-bottom: 4px; }

.uppy-DashboardItem-statusSize {
  display: inline-block;
  vertical-align: bottom;
  text-transform: uppercase; }

.uppy-DashboardItem-edit,
.uppy-DashboardItem-copyLink {
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }
  .uppy-DashboardItem-edit:hover,
  .uppy-DashboardItem-copyLink:hover {
    text-decoration: underline; }

.uppy-DashboardItem-edit:not(:first-child),
.uppy-DashboardItem-copyLink:not(:first-child),
.uppy-DashboardItem-sourceIcon:not(:first-child) {
  position: relative;
  margin-left: 14px; }
  .uppy-DashboardItem-edit:not(:first-child):before,
  .uppy-DashboardItem-copyLink:not(:first-child):before,
  .uppy-DashboardItem-sourceIcon:not(:first-child):before {
    content: '\B7';
    position: absolute;
    top: 0;
    left: -9px;
    color: #757575; }

.uppy-DashboardItem-action {
  position: absolute;
  top: 18px;
  right: 10px;
  z-index: 1002; }
  .uppy-size--md .uppy-DashboardItem-action {
    top: -8px;
    right: -8px; }

.uppy-DashboardItem-remove {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  color: #1f1f1f;
  width: 20px;
  height: 20px;
  padding: 1px;
  opacity: 0.9; }
  .uppy-DashboardItem-remove:hover {
    opacity: 1; }

.uppy-DashboardItem.is-inprogress:not(.is-resumable) .uppy-DashboardItem-remove {
  display: none; }

.uppy-DashboardItem-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1002;
  color: #fff;
  text-align: center;
  width: 120px;
  display: none;
  transition: all .35 ease; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress {
  -webkit-transform: initial;
          transform: initial;
  top: -9px;
  right: -8px;
  left: initial;
  width: auto; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-error .uppy-DashboardItem-progress {
  display: block; }

.uppy-DashboardItem-progressIndicator {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  display: inline-block;
  width: 38px;
  height: 38px;
  opacity: 0.9; }
  .uppy-size--md .uppy-DashboardItem-progressIndicator {
    width: 55px;
    height: 55px; }

button.uppy-DashboardItem-progressIndicator {
  cursor: pointer; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
  width: 18px;
  height: 18px; }
  .uppy-size--md .uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
    width: 28px;
    height: 28px; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1; }
  .uppy-size--md .uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    width: 22px;
    height: 22px; }

.uppy-DashboardItem-progressInfo {
  font-size: 9px;
  line-height: 1;
  font-weight: 500;
  height: 10px;
  display: none;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3); }
  .uppy-size--md .uppy-DashboardItem-progressInfo {
    display: block; }

.UppyIcon-progressCircle {
  width: 100%;
  height: 100%; }

.uppy-DashboardItem .bg {
  stroke: rgba(255, 255, 255, 0.4);
  opacity: 0; }

.uppy-DashboardItem .progress {
  stroke: #fff;
  transition: stroke-dashoffset .5s ease-out;
  opacity: 0; }

.uppy-DashboardItem .play {
  stroke: #fff;
  fill: #fff;
  opacity: 0;
  transition: all 0.2s;
  display: none; }

.uppy-DashboardItem .cancel {
  fill: #fff;
  opacity: 0;
  transition: all 0.2s; }

.uppy-DashboardItem .pause {
  stroke: #fff;
  fill: #fff;
  opacity: 0;
  transition: all 0.2s;
  display: none; }

.uppy-DashboardItem.is-error .retry {
  fill: #fff; }

.uppy-DashboardItem.is-resumable .pause, .uppy-DashboardItem.is-resumable .play {
  display: block; }

.uppy-DashboardItem.is-resumable .cancel {
  display: none; }

.UppyIcon-progressCircle .check {
  opacity: 0;
  fill: #fff;
  transition: all 0.2s; }

.uppy-DashboardItem.is-inprogress .bg, .uppy-DashboardItem.is-inprogress .progress, .uppy-DashboardItem.is-inprogress .pause, .uppy-DashboardItem.is-inprogress .cancel {
  opacity: 1; }

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-previewInnerWrap:after {
  display: block; }

.uppy-DashboardItem.is-error .uppy-DashboardItem-previewInnerWrap:after {
  display: block; }

.uppy-DashboardItem.is-paused .pause {
  opacity: 0; }

.uppy-DashboardItem.is-paused .play {
  opacity: 1; }

.uppy-DashboardItem.is-noIndividualCancellation .uppy-DashboardItem-progressIndicator {
  cursor: default; }

.uppy-DashboardItem.is-noIndividualCancellation .cancel {
  display: none; }

.uppy-DashboardItem.is-processing .uppy-DashboardItem-progress {
  opacity: 0; }

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
  cursor: default; }

.uppy-DashboardItem.is-complete .progress {
  stroke: #1bb240;
  fill: #1bb240;
  opacity: 1; }

.uppy-DashboardItem.is-complete .check {
  opacity: 1; }

.uppy-DashboardItem-progressNum {
  position: relative;
  z-index: 1001; }

.uppy-DashboardItem-progressInner {
  height: 15px;
  background-color: #2275d7;
  position: absolute;
  top: 0;
  left: 0; }

.uppy-Dashboard-actions {
  height: 55px;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #fafafa; }

.uppy-size--md .uppy-Dashboard-actions {
  height: 65px; }

.uppy-Dashboard-actionsBtn {
  margin-right: 10px; }

.uppy-Dashboard-pauseResume .UppyIcon {
  width: 100%;
  height: 100%; }

.uppy-Dashboard-upload {
  position: relative;
  width: 50px;
  height: 50px; }
  .uppy-size--md .uppy-Dashboard-upload {
    width: 60px;
    height: 60px; }

.uppy-Dashboard-upload .UppyIcon {
  position: relative;
  top: 1px;
  width: 50%; }

.uppy-Dashboard-uploadCount {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: #1bb240;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 8px; }
  .uppy-size--md .uppy-Dashboard-uploadCount {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 9px; }

.uppy-DashboardFileCard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column; }

.uppy-DashboardFileCard-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1; }

.uppy-DashboardFileCard-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafafa;
  /* no !important */
  position: relative; }

.uppy-DashboardFileCard-preview img {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  border-radius: 3px;
  position: absolute; }

.uppy-DashboardFileCard-info {
  padding: 30px 20px 20px 20px;
  max-height: 40%;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.uppy-DashboardFileCard-fieldset {
  font-size: 0;
  border: 0;
  padding: 0;
  max-width: 640px;
  margin: auto;
  margin-bottom: 12px; }

.uppy-DashboardFileCard-label {
  display: inline-block;
  vertical-align: middle;
  width: 22%;
  font-size: 12px;
  color: #525252; }
  .uppy-size--md .uppy-DashboardFileCard-label {
    font-size: 14px; }

.uppy-DashboardFileCard-input {
  display: inline-block;
  vertical-align: middle;
  width: 78%; }

/* lato-100normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin '),
    local('Lato-Thin'),
    url(/static/media/lato-latin-100.f8028e61.woff2) format('woff2'), 
    url(/static/media/lato-latin-100.752c2022.woff) format('woff'); /* Modern Browsers */
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin italic'),
    local('Lato-Thinitalic'),
    url(/static/media/lato-latin-100italic.ef884f11.woff2) format('woff2'), 
    url(/static/media/lato-latin-100italic.6ea83803.woff) format('woff'); /* Modern Browsers */
}

/* lato-300normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light '),
    local('Lato-Light'),
    url(/static/media/lato-latin-300.bbbd26ae.woff2) format('woff2'), 
    url(/static/media/lato-latin-300.ba2452bd.woff) format('woff'); /* Modern Browsers */
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light italic'),
    local('Lato-Lightitalic'),
    url(/static/media/lato-latin-300italic.7d718d72.woff2) format('woff2'), 
    url(/static/media/lato-latin-300italic.06eb177e.woff) format('woff'); /* Modern Browsers */
}

/* lato-400normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular '),
    local('Lato-Regular'),
    url(/static/media/lato-latin-400.f1a4a058.woff2) format('woff2'), 
    url(/static/media/lato-latin-400.62fb51e9.woff) format('woff'); /* Modern Browsers */
}

/* lato-400italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular italic'),
    local('Lato-Regularitalic'),
    url(/static/media/lato-latin-400italic.b45e52f3.woff2) format('woff2'), 
    url(/static/media/lato-latin-400italic.c4082958.woff) format('woff'); /* Modern Browsers */
}

/* lato-700normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold '),
    local('Lato-Bold'),
    url(/static/media/lato-latin-700.7fbbfd16.woff2) format('woff2'), 
    url(/static/media/lato-latin-700.96759e32.woff) format('woff'); /* Modern Browsers */
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold italic'),
    local('Lato-Bolditalic'),
    url(/static/media/lato-latin-700italic.c66215e0.woff2) format('woff2'), 
    url(/static/media/lato-latin-700italic.0a06ba1b.woff) format('woff'); /* Modern Browsers */
}

/* lato-900normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black '),
    local('Lato-Black'),
    url(/static/media/lato-latin-900.f2de2c6e.woff2) format('woff2'), 
    url(/static/media/lato-latin-900.d79dd066.woff) format('woff'); /* Modern Browsers */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black italic'),
    local('Lato-Blackitalic'),
    url(/static/media/lato-latin-900italic.fd67cf72.woff2) format('woff2'), 
    url(/static/media/lato-latin-900italic.e792d6c6.woff) format('woff'); /* Modern Browsers */
}


